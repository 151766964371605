import React, { useRef } from "react";
import Hero from "./components/Hero";
import Details from "./components/Details";
import Products from "./components/Products";
import MeetTheTeam from "./components/MeetTheTeam";
import ContactUs from "./components/ContactUs";

const Home = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Hero handleScrollToSection={handleScrollToSection} />
      <Details />
      <div ref={sectionRef}>
        <Products />
      </div>
      <MeetTheTeam />
      <ContactUs />
    </div>
  );
};

export default Home;
